<template>
	<v-container
		fluid
		fill-height
		class="background_gray"
	>
		<v-col
			v-show="true"
			cols="12"
			align="center"
			class="pa-0 pt-6"
		>
			<div class="guide_txt mb-4">
				<div class="icon">
					<div class="pb-2">
						<v-img
							max-width="30"
							max-height="30"
							:src="require('@/assets/img/estimate/ico_checkDone.svg')"
						/>
					</div>
				</div>
				<p class="mb-2 mb-md-2 tit fw_bold">상담 신청 완료!</p>
				<p class="mb-2 color_gray_7">작성해주신 내용 기반으로 상담 신청이 완료되었어요!</p>
			</div>
			<div class="estimateFinal mx-0">
				<v-col class="pa-0 sns item">
					<div class="pa-5 ma-0">
						<HomeInteriorPriceDetailinquireCommonFinal
							:selected-item="HOME_GET_CURRENT_ESTIMATE_CONTENT"
							:selected-userinfo="HOME_GET_CURRENT_ESTIMATE_USERINFO"
						/>
					</div>
				</v-col>
			</div>
		</v-col>
	</v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import common from '@/mixins/common'
import sms from '@/mixins/sms'
import router from '@/router'

export default {
	name: 'HomeInteriorPriceNewFinalinquire',
	mixins: [common, sms],
	data: () => ({}),
	computed: {
		...mapGetters([
			'AUTH_GET_USER_AUTH',
			'GUEST_GET_ESTIMATE_DETAIL',
			'HOME_GET_CURRENT_ESTIMATE_CONTENT',
			'HOME_GET_CURRENT_ESTIMATE_USERINFO',
		]),
		// ...mapGetters(['HOME_GET_CURRENT_ESTIMATE_CONTENT', 'HOME_GET_CURRENT_ESTIMATE_USERINFO']),
	},
	watch: {},
	created() {},
	mounted() {
		if (this.HOME_GET_CURRENT_ESTIMATE_CONTENT.item === null) {
			this.$router.go(-1)
			alert('신청내역에서 상담내용을 확인하세요.')
		}
		// if (this.HOME_GET_CURRENT_ESTIMATE_CONTENT.product === '' || this.HOME_GET_CURRENT_ESTIMATE_CONTENT.product === null) {
		// 	alert('신청내역에서 상담내용을 확인하세요.')
		// 	if (this.AUTH_GET_USER_AUTH === 'USER') return this.$router.push('/user?tab=2')
		// 	else if (this.AUTH_GET_USER_AUTH === 'GUEST') return this.$router.push('/interior/mypage/guest')
		// }
	},
	destroyed() {},
	methods: {
		...mapMutations(['GUEST_MU_ESTIMATE', 'GUEST_MU_ESTIMATE_DETAIL']),
		...mapActions(['AUTH_ACT_SIGN_UP_CHECK', 'GUEST_ACT_ESTIMATE']),
	},
}
</script>

<style scoped lang="scss">
.tit {
	font-size: $font_lg;
	font-weight: $fw_bold;
}
.estimateFinal {
	background-color: $color_white;
	border-radius: 16px;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
	max-width: 412px;
	.tit {
		font-size: $font_lg;
		font-weight: $fw_bold;
	}
	:deep(.bt) {
		visibility: hidden;
	}
}
@media all and (max-width: 959px) {
	.estimateFinal {
		border-radius: 0;
		box-shadow: none;
		max-width: 100%;
	}
}
</style>
